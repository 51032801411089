import React from "react";

import logo from "../assets/img/logobl.png";

const Receipt = (props) => {
  const { order, kitchenTicket = false, etd, vatTicket = false } = props;

  let previousLabel = "";

  return (
    <div className="hidden print:block flex flex-col flex-grow bg-white text-sm">
      {!kitchenTicket && (
        <>
          <img src={logo} alt="Logo Den Baba" className="h-24 w-32 mx-auto" />
          <span className="text-xs text-center my-4 item">
            <p>Den Baba</p>
            <p>Gentsesteenweg 129</p>
            <p>9300, Aalst</p>
            <p>053 40 00 50</p>
            <p>{order.created.toDate().toLocaleString("nl-BE")}</p>
          </span>
        </>
      )}
      {vatTicket && (
        <span className="text-center my-4">
          <p className="font-bold">BTW-kasticket</p>
          <p className="text-sm">Ticketnummer: 16</p>
        </span>
      )}
      {order && (
        <span className="text-left font-bold my-8">
          {!kitchenTicket && <p>{`${order.firstName} ${order.lastName}`}</p>}
          {order.delivery && (
            <span>
              <p>{`${order.street} ${order.number} ${
                order.bus && `/ ${order.bus}`
              }`}</p>
              <p>{`${order.postalCode}, ${order.city}`}</p>
            </span>
          )}
          {!kitchenTicket && <p>{order.telephone}</p>}
        </span>
      )}
      {order &&
        !vatTicket &&
        (order.time === "asap" ? (
          <p className="my-4">
            {order.delivery ? "Levering " : "Afhaling "}
            <span className="text-lg font-bold">
              {`Zo snel mogelijk `}
              <span className="text-sm font-medium">{`${
                etd ? `(circa ${etd})` : order.etd ? `(circa ${order.etd})` : ""
              } `}</span>
            </span>
          </p>
        ) : (
          <p className="my-4">
            {order.delivery ? "Leveren om " : "Afhalen om "}
            <span className="text-lg font-bold">{order.time}</span>
          </p>
        ))}
      {vatTicket && (
        <div className="mt-4">
          <div className="flex flex-row justify-between">
            <p
              className={`flex-grow-0 w-8 text-right mr-4 font-bold
            `}>
              #
            </p>
            <span className="flex flex-col flex-grow">
              <p className="font-bold">Naam</p>
            </span>
            <p className="flex-grow-0 font-bold">Prijs</p>
            <p className="flex-grow-0 font-bold ml-4">BTW</p>
          </div>
          <hr></hr>
        </div>
      )}
      {order &&
        order.items.map((it) => (
          <span key={it.id}>
            <div className="flex flex-row justify-between" key={it.id}>
              <p
                className={`flex-grow-0 w-8 text-right mr-4 ${
                  it.qty > 1 && "font-bold"
                }`}>
                {it.qty} x
              </p>
              <span className="flex flex-col flex-grow">
                <p className="font-bold">
                  {it.size ? `${it.name} - ${it.size}` : it.name}
                </p>
              </span>
              <p className="flex-grow-0 text-right">
                {((it.price * it.qty) / 100).toFixed(2).replace(".", ",")}{" "}
                &euro;
              </p>
              {vatTicket && <p className="flex-grow-0 ml-4">{it.vat}%</p>}
            </div>
            <div className="flex flex-col justify-between">
              {it.extras.map((ex) => {
                const paragraph = ex.stepperLabel ? (
                  <span className="flex flex-row" key={ex.id}>
                    <span className="w-12 flex-grow-0">
                      {previousLabel !== ex.stepperLabel && (
                        <p className="font-bold text-xs">{ex.stepperLabel}</p>
                      )}
                    </span>
                    <span className="ml-4 ">
                      <p className="text-sm">+ {ex.name}</p>
                    </span>
                  </span>
                ) : (
                  <span className="ml-16" key={ex.id}>
                    <p className="text-sm">+ {ex.name}</p>
                  </span>
                );

                previousLabel = ex.stepperLabel ? ex.stepperLabel : "";

                return paragraph;
              })}
            </div>
          </span>
        ))}
      {!kitchenTicket && (
        <>
          <hr className="my-2" />
          {order && order.coupon && (
            <>
              <p className="text-right ">
                {((order.coupon.discount + order.totalPrice) / 100)
                  .toFixed(2)
                  .replace(".", ",")}{" "}
                &euro;
              </p>
              <p className="text-right ">
                {`${order.coupon.label} ${(0 - order.coupon.discount / 100)
                  .toFixed(2)
                  .replace(".", ",")}`}{" "}
                &euro;
              </p>
            </>
          )}

          {vatTicket ? (
            <>
              <div className="grid grid-cols-2 gap-2 my-2 text-lg">
                <p className="font-bold">Totaal</p>
                <p className="text-right font-bold">
                  {order &&
                    (order.totalPrice / 100).toFixed(2).replace(".", ",")}{" "}
                  &euro;
                </p>
              </div>
              <hr></hr>
              <div className="grid grid-cols-2 gap-2 my-2">
                <p className="font-semibold">Betaling</p>
                <p className="font-semibold text-right">Bedrag</p>
                <p>Bancontact (online betaald)</p>
                <p className="text-right">
                  {order &&
                    (order.totalPrice / 100).toFixed(2).replace(".", ",")}{" "}
                  &euro;
                </p>
                <p className="font-bold">Betaald</p>
                <p className="text-right font-bold">
                  {order &&
                    (order.totalPrice / 100).toFixed(2).replace(".", ",")}{" "}
                  &euro;
                </p>
              </div>
              <hr></hr>
              <div className="grid grid-cols-4 gap-2 my-2">
                <p className="col-span-2 text-right font-semibold">Netto</p>
                <p className="text-right font-semibold">BTW</p>
                <p className="text-right font-semibold">Totaal</p>

                <p className="text-right">6%</p>
                <p className="text-right">
                  {order &&
                    (order.totalPrice / 100 - (order.totalPrice / 100) * 0.06)
                      .toFixed(2)
                      .replace(".", ",")}{" "}
                  &euro;
                </p>
                <p className="text-right">
                  {order &&
                    ((order.totalPrice / 100) * 0.06)
                      .toFixed(2)
                      .replace(".", ",")}{" "}
                  &euro;
                </p>
                <p className="text-right">
                  {order &&
                    (order.totalPrice / 100).toFixed(2).replace(".", ",")}{" "}
                  &euro;
                </p>

                <p className="text-right">BTW totaal</p>
                <p className="text-right font-semibold">
                  {order &&
                    (order.totalPrice / 100 - (order.totalPrice / 100) * 0.06)
                      .toFixed(2)
                      .replace(".", ",")}{" "}
                  &euro;
                </p>
                <p className="text-right font-semibold">
                  {order &&
                    ((order.totalPrice / 100) * 0.06)
                      .toFixed(2)
                      .replace(".", ",")}{" "}
                  &euro;
                </p>
                <p className="text-right font-semibold">
                  {order &&
                    (order.totalPrice / 100).toFixed(2).replace(".", ",")}{" "}
                  &euro;
                </p>
              </div>
              <hr></hr>
            </>
          ) : (
            <>
              <div>
                {order && order.payment === "online" ? (
                  <div className="text-right font-bold">
                    <p>Online betaald</p>
                  </div>
                ) : (
                  order && (
                    <div className="flex flex-row justify-between">
                      <p className="font-semi-bold">Te betalen:</p>
                      <p className="font-bold text-right text-lg">
                        {(order.totalPrice / 100).toFixed(2).replace(".", ",")}{" "}
                        &euro;
                      </p>
                    </div>
                  )
                )}
              </div>

              <p className="mb-4 font-bold">
                {order && !vatTicket && order.comment}
              </p>
              <p className="text-center text-sm mt-2">Bedankt en smakelijk!</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Receipt;
