import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDvDyaYrVDk9wkkIpfuva3Ww9SUQ7poGrA",
  authDomain: "denbaba-59dbd.firebaseapp.com",
  databaseURL: "https://denbaba-59dbd.firebaseio.com",
  projectId: "denbaba-59dbd",
  storageBucket: "denbaba-59dbd.appspot.com",
  messagingSenderId: "915177806652",
  appId: "1:915177806652:web:99cfb7f465a1a7b25d5c39",
  measurementId: "G-MTQSJLFLGW",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firestoreTimestamp = firebase.firestore.Timestamp;
export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
