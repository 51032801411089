import React from "react";

import SyncLoader from "react-spinners/HashLoader";

const Loading = () => {
  return (
    <div className="flex flex-col flex-grow justify-center items-center">
      <SyncLoader color="white" />
    </div>
  );
};

export default Loading;
