import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import * as moment from "moment";

const AsapDialog = (props) => {
  const standardMinutes = [30, 60, 90, 120];

  const { order, open, setShowAsapDialog, setEtd, setIsPrinting } = props;

  const handleConfirmTime = (m) => {
    const today = moment();
    const etd = today.add({ minutes: m }).format("H:mm").toString();
    setEtd(etd);
    order.ref.set({ status: "confirmed", etd: etd }, { merge: true });
    setTimeout(() => {
      window.print();
      setEtd(null);
      setShowAsapDialog(false);
      // setIsPrinting(false);
    }, 250);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" className="print:hidden">
      <DialogContent className="flex flex-col justify-center">
        {order && (
          <span className="text-left mb-4">
            <p>{`${order.firstName} ${order.lastName}`}</p>
            {order.delivery && (
              <span>
                <p>{`${order.street} ${order.number} ${order.bus}`}</p>
                <p>{`${order.postalCode}, ${order.city}`}</p>
              </span>
            )}
            <p>{order.telephone}</p>
          </span>
        )}
      </DialogContent>
      <DialogActions>
        {standardMinutes.map((m, i) => (
          <Button
            className="h-24 w-24"
            variant="contained"
            size="large"
            key={i}
            color="primary"
            onClick={() => handleConfirmTime(m)}>
            <span className="text-3xl font-bold">{m}</span>
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default AsapDialog;
