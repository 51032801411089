import React, { useState, useEffect, useCallback } from "react";

import LogoDenBaba from "../assets/img/logo.png";
import { useSession } from "../providers/UserProvider";
import { auth, db } from "../firebase/firebase";

import * as moment from "moment";
import {
  Truck,
  ShoppingBag,
  CreditCard,
  Printer,
  Eye,
  Settings,
} from "react-feather";
import { IconButton } from "@material-ui/core";

import UIfx from "uifx";
import AlertAudio from "../assets/sounds/alarm.wav";
import OrderDialog from "./OrderDialog";
import Loading from "./Loading";

import Receipt from "./Receipt";
import { Link } from "react-router-dom";
import AsapDialog from "./AsapDialog";

moment.locale("nl-BE");

const Home = () => {
  const user = useSession();

  let webContents = window.webContents;

  const alertSound = new UIfx(AlertAudio, { volume: 1 });

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [playSound, setPlaySound] = useState(false);
  const [kitchenPrint, setKitchenPrint] = useState(false);
  const [showAsapDialog, setShowAsapDialog] = useState(false);
  const [etd, setEtd] = useState(null);

  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    const today = moment();

    // const start = moment("31-07-2022 00:00:00", "DD-MM-YYYY hh:mm:ss").toDate();
    // const end = moment("31-07-2022 23:59:59", "DD-MM-YYYY hh:mm:ss").toDate();
    const start = today.startOf("day").toDate();
    const end = today.endOf("day").toDate();

    // console.log(start);

    const unsubscribe = db
      .collection("orders")
      .where("created", ">=", start)
      .where("created", "<", end)
      .where("status", "in", ["new", "confirmed"])
      .onSnapshot((qs) => {
        setLoading(false);

        const receivedOrders = qs.docs.map((ds) => {
          return { ...ds.data(), id: ds.id, ref: ds.ref };
        });

        setNewOrders(
          receivedOrders
            .filter((o) => o.status === "new")
            .sort((a, b) => {
              return a.time === b.time
                ? 0
                : a.time === "asap"
                ? 1
                : a.time > b.time
                ? 1
                : -1;
            })
        );

        setOrders(
          receivedOrders.sort((a, b) => {
            if (a.time > b.time) {
              return -1;
            } else if (a.time < b.time) {
              return 1;
            }

            if (a.time === "asap" && b.time === "asap" && a.etd > b.etd) {
              return -1;
            } else if (
              a.time === "asap" &&
              b.time === "asap" &&
              a.etd < b.etd
            ) {
              return 1;
            }

            return 0;
          })
        );
      });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (playSound === true) {
      alertSound.play(1);
      setPlaySound(false);
    }
  }, [alertSound, playSound]);

  useEffect(() => {
    let timer;

    if (newOrders.length > 0) {
      const firstOrder = newOrders[0];
      setSelectedOrder(firstOrder);
      setKitchenPrint(false);
      if (firstOrder.time === "asap") {
        setPlaySound(true);
        timer = setTimeout(() => {
          setShowAsapDialog(true);
        }, 250);
      } else {
        setPlaySound(true);
        setShowAsapDialog(false);
        timer = setTimeout(() => {
          window.print();
        }, 250);
      }
    }

    return () => clearTimeout(timer);
  }, [newOrders]);

  const handleAfterPrint = useCallback(
    (_) => {
      if (
        selectedOrder &&
        selectedOrder.time !== "asap" &&
        selectedOrder.status === "new"
      ) {
        selectedOrder.ref.set({ status: "confirmed" }, { merge: true });
      }
    },
    [selectedOrder]
  );

  useEffect(() => {
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, [handleAfterPrint]);

  return (
    <>
      {selectedOrder && (
        <Receipt etd={etd} order={selectedOrder} kitchenTicket={kitchenPrint} />
      )}
      {selectedOrder && showAsapDialog && (
        <AsapDialog
          order={selectedOrder}
          open={showAsapDialog}
          setEtd={setEtd}
          setIsPrinting={setIsPrinting}
          setShowAsapDialog={setShowAsapDialog}
        />
      )}
      <div className="flex-grow p-4 select-none print:hidden">
        {/* {selectedOrder && <Receipt order={selectedOrder} />} */}
        <OrderDialog
          className="w-full"
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          order={selectedOrder}
        />
        <div className="flex flex-row justify-between items-center">
          <img src={LogoDenBaba} alt="Logo den baba" className="w-auto h-12" />
          <button
            className="btn text-white border-white focus:outline-none"
            onClick={() => auth.signOut()}>
            {user.email}
          </button>
        </div>
        <div className="flex flex-col flex-wrap justify-center">
          <span className="flex flex-row justify-between items-center">
            <p className="uppercase text-3xl text-white font-thin my-4">
              Bestellingen{" "}
              <span className="text-sm font-hairline">
                {moment().toString()}
              </span>
            </p>
            <Link to="/products">
              <Settings color="white" />
            </Link>
          </span>

          <div className="flex flex-row justify-around my-4">
            <div className="text-white bg-teal-500 rounded p-4 shadow-lg w-1/6">
              <p className="font-black text-3xl text-right">
                {(orders.reduce((acc, curr) => acc + curr.totalPrice, 0) / 100)
                  .toFixed(2)
                  .replace(".", ",")}
                &euro;
              </p>
              <p className="text-right text-sm font-thin">Totaal</p>
            </div>
            <div className="text-white bg-teal-500 rounded p-4 shadow-lg w-1/6">
              <p className="font-black text-3xl text-right">
                {(
                  orders.reduce((acc, curr) => {
                    if (
                      curr.onlinePayment &&
                      curr.onlinePayment.status === "paid"
                    ) {
                      return acc + curr.totalPrice;
                    }
                    return acc;
                  }, 0) / 100
                )
                  .toFixed(2)
                  .replace(".", ",")}
                &euro;
              </p>
              <p className="text-right text-sm font-thin">Online</p>
            </div>
            <div className="text-white bg-teal-500 rounded p-4 shadow-lg w-1/6">
              <p className="font-black text-3xl text-right">
                {(
                  orders.reduce((acc, curr) => {
                    if (curr.payment === "cash") {
                      return acc + curr.totalPrice;
                    }
                    return acc;
                  }, 0) / 100
                )
                  .toFixed(2)
                  .replace(".", ",")}
                &euro;
              </p>
              <p className="text-right text-sm font-thin">Cash</p>
            </div>
            <div className="text-white bg-teal-500 rounded p-4 shadow-lg w-1/6">
              <p className="font-black text-3xl text-right">
                {orders.filter((o) => !o.delivery).length}
              </p>
              <p className="text-right text-sm font-thin">Afhalingen</p>
            </div>
            <div className="text-white bg-teal-500 rounded p-4 shadow-lg w-1/6">
              <p className="font-black text-3xl text-right">
                {orders.filter((o) => o.delivery).length}
              </p>
              <p className="text-right text-sm font-thin ">Leveringen</p>
            </div>
          </div>
          <div className="flex flex-col justify-end items-end my-4 text-gray-500">
            <span className="flex flex-row">
              <p className="mr-2">BTW 6%</p>
              <p>
                {orders
                  .reduce(
                    (acc, curr) =>
                      acc +
                      curr.items.reduce(
                        (a, c) =>
                          a + (c.vat === 6 ? (c.qty * c.price) / 100 : 0),
                        0
                      ) -
                      (curr.coupon ? curr.coupon.discount / 100 : 0),
                    0
                  )
                  .toFixed(2)
                  .replace(".", ",")}{" "}
                &euro;
              </p>
            </span>
            <span className="flex flex-row">
              <p className="mr-2">BTW 21%</p>
              <p>
                {orders
                  .reduce(
                    (acc, curr) =>
                      acc +
                      curr.items.reduce(
                        (a, c) =>
                          a + (c.vat === 21 ? (c.qty * c.price) / 100 : 0),
                        0
                      ),
                    0
                  )
                  .toFixed(2)
                  .replace(".", ",")}{" "}
                &euro;
              </p>
            </span>
          </div>
          <div className="grid grid-cols-3 gap-2">
            {!loading ? (
              orders.map((o) => (
                <div className="w-full mr-4 select-none" key={o.id}>
                  <div className="lg:w-48 flex-none bg-cover rounded text-center overflow-hidden"></div>
                  <div
                    className={`border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal h-64 ${
                      o.status === "new" && "bg-red-300"
                    }`}>
                    <div className="mb-8">
                      <div className="text-gray-600 flex items-center justify-between">
                        <p className="font-bold text-xl">
                          {o.time === "asap"
                            ? `ZSM (${o.etd ? o.etd : ""})`
                            : o.time}
                        </p>
                        {o.delivery ? <Truck /> : <ShoppingBag />}
                      </div>
                      <div className="text-gray-900 font-bold text-xl mb-2">
                        <p>{`${o.firstName} ${o.lastName}`}</p>
                      </div>
                      {o.delivery && (
                        <>
                          <p className="text-gray-700 text-base">
                            {`${o.street} ${o.number} ${
                              o.bus ? ` / ${o.bus}` : ""
                            }`}
                          </p>
                          <p className="text-gray-700 text-base">{`${o.postalCode} ${o.city}`}</p>
                        </>
                      )}
                    </div>
                    <div className="flex flex-row justify-between items-center text-gray-600">
                      <div className="flex flex-row">
                        {o.status !== "pending" &&
                          o.payment === "online" &&
                          o.onlinePayment.status === "paid" && <CreditCard />}
                        {o.payment === "cash" && <p>&euro;</p>}
                        <p className="text-gray-600 ml-4">
                          {o.coupon !== null
                            ? (o.totalPrice / 100).toFixed(2).replace(".", ",")
                            : (o.totalPrice / 100)
                                .toFixed(2)
                                .replace(".", ",")}{" "}
                          &euro;
                        </p>
                      </div>
                      <IconButton
                        size="medium"
                        className="focus:outline-none"
                        onClick={() => {
                          setSelectedOrder(o);
                          setOpenDialog(true);
                        }}>
                        <Eye />
                      </IconButton>
                      <IconButton
                        size="medium"
                        className="focus:outline-none"
                        onClick={() => {
                          setSelectedOrder(o);
                          setTimeout(() => {
                            setKitchenPrint(false);
                            if (webContents) {
                              webContents.print({ silent: true, copies: 1 });
                            } else {
                              window.print();
                            }
                          }, 500);
                          // setTimeout(() => {
                          //   setKitchenPrint(true);
                          //   if (webContents) {
                          //     webContents.print({ silent: true, copies: 1 });
                          //   } else {
                          //     window.print();
                          //   }
                          // }, 500);
                        }}>
                        <Printer />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Loading />
            )}
          </div>
        </div>
        <span></span>
      </div>
    </>
  );
};

export default Home;
