import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import { Switch } from "@material-ui/core";
import { Home } from "react-feather";
import { grey } from "@material-ui/core/colors";
import { Link } from "react-router-dom";

import * as moment from "moment";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [today, setToday] = useState(null);
  const [enabled, setEnabled] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const [asapEnabled, setAsapEnabled] = useState(false);

  useEffect(() => {
    db.collection("products")
      .orderBy("name")
      .get()
      .then((qs) =>
        setProducts(
          qs.docs.map((ds) => {
            return { id: ds.id, ...ds.data() };
          })
        )
      );
  }, []);

  useEffect(() => {
    const today = moment();
    const weekDay = today.isoWeekday();
    db.collection("hours")
      .where("day", "==", weekDay)
      .get()
      .then((ds) => {
        if (!ds.empty) {
          setToday({ id: ds.docs[0].id, ...ds.docs[0].data() });
        }
      });
  }, []);

  useEffect(() => {
    if (today !== null) {
      setAsapEnabled(today.asap);
      setIsOpen(today.isOpen);
    }
  }, [today]);

  useEffect(() => {
    if (products.length) {
      setEnabled(
        products.reduce(
          (a, b) => ((a[b.id] = { isEnabled: b.isEnabled, sizes: b.sizes }), a),
          {}
        )
      );
    }
  }, [products]);

  const changeEnabled = (event, tet) => {
    setEnabled({
      ...enabled,
      [event.target.name]: {
        ...enabled[event.target.name],
        isEnabled: event.target.checked,
      },
    });

    db.collection("products").doc(event.target.name).set(
      {
        isEnabled: event.target.checked,
      },
      { merge: true }
    );
  };

  const changeSizeEnabled = (event, idx) => {
    const productId = event.target.name;
    const pOld = enabled[productId];

    let newSizes = pOld.sizes;
    const oldSize = enabled[productId].sizes[idx];
    const newSize = { ...oldSize, isEnabled: event.target.checked };

    newSizes[idx] = newSize;

    db.collection("products")
      .doc(productId)
      .set(
        {
          sizes: newSizes,
        },
        { merge: true }
      )
      .then(() => {
        const pNew = { ...pOld, sizes: newSizes };
        setEnabled({ ...enabled, [productId]: pNew });
      });
  };

  const changeAsap = (event) => {
    const newIsAsap = !asapEnabled;
    db.collection("hours")
      .doc(event.target.name)
      .set(
        {
          asap: newIsAsap,
        },
        { merge: true }
      )
      .then(() => setAsapEnabled(newIsAsap));
  };

  const changeIsOpen = (event) => {
    const newIsOpen = !isOpen;
    db.collection("hours")
      .doc(event.target.name)
      .set(
        {
          isOpen: newIsOpen,
        },
        { merge: true }
      )
      .then(() => setIsOpen(newIsOpen));
  };

  return (
    <div className="p-4">
      <span className="flex flex-row justify-between items-center">
        <p className="uppercase text-3xl text-white font-thin my-4">Opening</p>
        <Link to="/">
          <Home color="white" size={24} />
        </Link>
      </span>
      {today && (
        <>
          <p className="font-bold text-xl text-white">
            {today.label} (vandaag)
          </p>
          <span className="flex flex-row justify-between px-6 py-4 text-white">
            <p>Zo snel mogelijk?</p>
            <Switch
              name={today.id}
              checked={asapEnabled !== null && asapEnabled}
              onChange={changeAsap}></Switch>
          </span>
          <span className="flex flex-row justify-between px-6 py-4 text-white">
            <p>Vandaag open?</p>
            <Switch
              name={today.id}
              checked={isOpen !== null && isOpen}
              onChange={changeIsOpen}></Switch>
          </span>
        </>
      )}

      <span className="flex flex-row justify-between items-center">
        <p className="uppercase text-3xl text-white font-thin my-4">
          Producten
        </p>
      </span>

      {products.map((p) => (
        <div key={p.id} className="px-2">
          <span
            key={p.id}
            className="flex flex-row justify-between px-6 py-4 text-white">
            <p className="font-bold text-xl">{p.name}</p>
            <Switch
              name={p.id}
              checked={enabled && enabled[p.id].isEnabled}
              onChange={changeEnabled}></Switch>
          </span>
          <span className="flex flex-col justify-between px-6 py-4 text-white">
            {p.sizes &&
              p.sizes.map((s, idx) => (
                <span
                  key={s.name.toLowerCase()}
                  className="flex flex-row justify-between">
                  <p>{s.name}</p>
                  <Switch
                    name={p.id}
                    checked={enabled && enabled[p.id].sizes[idx].isEnabled}
                    onChange={(e) => changeSizeEnabled(e, idx)}
                  />
                </span>
              ))}
          </span>
          <hr style={{ borderColor: grey[700] }} />
        </div>
      ))}
    </div>
  );
};

export default Products;
