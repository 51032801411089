import React from "react";
import "./App.css";
import { useAuth, UserContext } from "./providers/UserProvider";
import Home from "./components/Home";
import Login from "./components/Login";
import Loading from "./components/Loading";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import Products from "./components/Products";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0d47a1",
    },
    secondary: {
      light: "#42ad8d",
      main: "#117374",
    },
  },
});

function App() {
  const { initializing, user } = useAuth();
  if (initializing) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <UserContext.Provider value={{ user }}>
          {user ? (
            <Switch>
              <Route path="/products">
                <Products />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          ) : (
            <Login />
          )}
        </UserContext.Provider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
