import React, { useState } from "react";

import Logo from "../assets/img/logo.png";
import { auth } from "../firebase/firebase";

import { TextField, Button } from "@material-ui/core";

import PulseLoader from "react-spinners/PulseLoader";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        if (user) {
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col h-screen justify-between items-center">
      <img
        src={Logo}
        alt="Den Baba logo"
        className="h-auto w-32 shadow-lg mt-4"
      />
      <form
        className="flex flex-col bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4 w-full max-w-xl"
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
      >
        {error !== null && (
          <div className="py-4 rounded bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <div className="mb-4">
          <TextField
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => {
              setError(null);
              setEmail(e.target.value);
            }}
            autoComplete="email"
            error={error !== null}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
        </div>
        <div className="mb-6">
          <TextField
            label="Wachtwoord"
            fullWidth
            value={password}
            type="password"
            autoComplete="current-password"
            onChange={(e) => {
              setError(null);
              setPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
            error={error !== null}
          />
        </div>

        <div className="flex items-center justify-center">
          <Button
            className="focus:outline-none"
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleLogin}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          >
            {loading ? <PulseLoader color="white" size={8} /> : "Inloggen"}
          </Button>
        </div>
      </form>
      <p className="text-center text-gray-500 text-xs font-thin mb-4">
        &copy; 2020 BreckxIT. All rights reserved.
      </p>
    </div>
  );
};

export default Login;
