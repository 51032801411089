import { createContext, useState, useEffect, useContext } from "react";
import { auth } from "../firebase/firebase";

export const UserContext = createContext({ user: null });

export const useSession = () => {
  const { user } = useContext(UserContext);
  return user;
};

export const useAuth = () => {
  const [state, setState] = useState(() => {
    const user = auth.currentUser;
    return { initializing: !user, user };
  });
  const onChange = (user) => {
    setState({ initializing: false, user: user });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);
  return state;
};
